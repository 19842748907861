.floor-plan {
    width: 100%;
    height: 100vh;
}

.inventory-header {
    margin-top: 10px;
}

.header-line {
    width: 100%;
    height: 1px;
    background: #e0dddd;
}

.inventory-header-row, .inventory-header-row-top {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.inventory-header-row {
    margin-top: 5px;
    margin-bottom: 15px;
}

.inventory-header-row .floor-plan-btn {
    padding: 10.5px 9px;
    min-width: 90px;
}

.inventory-header-row-top {
    justify-content: space-between;
}

.breadcrumb {
    margin-bottom: 15px;
}

.breadcrumb .breadcrumb-back{
    cursor: pointer;
    color: #3A00FF;
}

.logout {
    text-align: right;
}

.inventory-header-info {
    margin-bottom: 15px;
    margin-right: 20px;
    min-height: 20px;
    font-weight: bold;
}

.change-floor {
    min-width: 150px;
}

.Select {
    width: 444px !important;
    margin-right: 5px;
    z-index: 0 !important;
}

.Select.is-open {
	z-index: 1 !important;
}

.Select-menu {
    position: fixed !important;
    width: 440px !important;
    margin: 1px;
}

.download-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.floor-plan-btn.secondary.download {
    text-decoration: none;
    color: #3A00FF;
    border: 1px solid #3A00FF;
    border-radius: 3px;
    padding: 10.5px 9px;
}

.download-error {
    color: #990000;
    border: 2px solid #990000;
    background: #FFCCCC;
    border-radius: 3px;
    width: 325px;
    padding: 8px 5px;
    text-align: center;
}

.input-container.room-input-container {
    margin-left: 15px;
    margin-right: 15px;
    width: 150px;
}

.input-container.room-input-container .input {
    min-width: 150px;
    padding: 10.5px 9px;
}

.input-container.room-input-container .result {
    top: 23px;
    width: 167px;
}

.over-map-top-container {
    position: fixed;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.inventory-summary {
    position: fixed;
    top: 145px;
    left: 56px;
    z-index: 1;
    background: white;
    border: 1px solid #e0dddd;
    border-radius: 3px;
    padding: 12px 10px 7px 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.inventory-summary-name {
    font-weight: bold;
}

.inventory-summary-item {
    padding-left: 20px;
}

.inventory-summary-item-icon {
    margin-right: 5px;
}

.inventory-summary-item span {
    vertical-align: top;
}

.inventory-info-section {
    position: fixed;
    top: 120px;
    right: 5px;
    z-index: 1;
    background: white;
    border: 1px solid #e0dddd;
    border-radius: 3px;
    padding: 5px;
}

.toggle-border {
    margin-left: 10px;
}

.floor-plan-btn.toggle-left, .floor-plan-btn.secondary.toggle-left,
.floor-plan-btn.toggle-right, .floor-plan-btn.secondary.toggle-right {
    padding: 10.5px 9px;
    border: 1px solid #3a00ff;
    min-width: 100px;
}

.floor-plan-btn.toggle-left, .floor-plan-btn.secondary.toggle-left {
    border-radius: 3px 0px 0px 3px;
}

.floor-plan-btn.toggle-right, .floor-plan-btn.secondary.toggle-right {
    border-radius: 0px 3px 3px 0px;
}

.visual-validation-status-link {
    padding: 10px;
    padding-left: 5%;
    background: #efeaf9;
}

.visual-validation-status-link a {
    color: blue;
}

.hide-furniture-building {
    margin-left: 25px;
}