.floor-plan {
    width: 100%;
    height: 100vh;
}

.floor-plan-map {
    width: 100%;
}

.visual-validation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.visual-validation-current {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 40px;
}

.visual-validation-floors-wrapper {
    height: 90vh;
    overflow: scroll;
    padding-right: 2px;
}

.visual-left-panel-floor {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0dddd;
    border-radius: 5px;
    padding: 10px;
}

.visual-left-panel {
    width: 275px;
    height: 100vh;
    background: white;
    border-right: 1px solid #e0dddd;
    padding: 10px;
}


.visual-left-panel-floor-title {
    font-weight: bold;
    color: #3A00FF;
    cursor: pointer;
}

.visual-left-panel-floor-title-status {
    font-size: 8px;
    color: #b3b3b3;
    border-radius: 2px;
    border: 1px solid #b3b3b3;
    padding: 1px;
    margin-right: 5px;
}

.visual-left-panel-floor-wrapper {
    border-bottom: 1px solid #e0dddd;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #3A00FF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.visual-left-panel-floor-view {
    padding-top: 15px;
    padding-bottom: 5px;
    color: #3A00FF;
    cursor: pointer;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.visual-left-panel-floor-actions {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    margin-top: 5px;
}

.visual-left-panel-floor-actions .floor-plan-btn.secondary, .modal-submin-title-actions .floor-plan-btn {
    padding: 10px;
    min-width: 80px;
    border: 1px solid #3A00FF;
}

.visual-left-panel-floor-actions .floor-plan-btn.secondary.reject {
    color: red;
    border: 1px solid red;

}

.visual-left-panel-floor-actions .floor-plan-btn.secondary.approve {
    color: white;
    background: #12b83f;
    border: 1px solid #12b83f
}

.visual-left-panel-floor-actions .floor-plan-btn.secondary:disabled {
    color: #f3f3f3;
    background: #ccc;
    border: 1px solid #ccc;
  }

.modal-submin-title-actions .floor-plan-btn.done {
    margin-left: 10px;
}

.visual-validation-status {
    font-size: 10px;
    padding: 2px;
    background: #ffcc00;
    border: 1px solid #ffcc00;
    border-radius: 5px;
    margin-right: 10px;
}

.visual-validation-status.done {
    background: #12b83f;
    border: 1px solid #12b83f;
}

.modal-submin-title {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: large;
}

.visual-validation-done-title {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
}

.modal-submin-title-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.visual-validation .modal-form .modal-form-body {
    padding-top: 0px;
}

.view-floor-icon {
    height: 12px;
    margin-right: 5px;
}

.visual-left-panel-floor-status-wrapper {
    min-width: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.done-after-close {
    margin-top: 5px;
    padding: 8px 10px;
    min-width: 100%;
}

.harvested-on-date {
    font-weight: normal;
}

.info-section-wrapper {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 1;
    background: white;
    border: 1px solid #e0dddd;
    border-radius: 0px 0px 0px 3px;
    padding: 5px;
}