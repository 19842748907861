
.info-section {
    width: 310px;
    padding: 2px;
}

.room-number {
    font-weight: bold;
    width: 120px;
}

.info-section-row-office {
    font-weight: bold;
    padding: 5px 0;
}

.info-section-row {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 10px 0;
}

.info-section-row-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 5px 0;
    margin-bottom: 15px;
}

.mapped-to-revit-record-model {
    position: relative;
}

.copied-to-clipboard {
    width: 100%;
    text-align: center;
    position: absolute;
    top: -30px;
}

.copied-to-clipboard-item {
    padding: 5px;
    border: 1px solid #e0dddd;
    color: #e0dddd;
    border-radius: 12px;
}

.info-section-row-copy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e0dddd;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.info-section-row-copy.coppied {
    border: 1px solid #3A00FF;
}

.info-section-id-title {
    position: absolute;
    top: -7px;
    left: 13px;
    background: white;
    color: #e0dddd;
}

.info-section-id-title.coppied { 
    color: #3A00FF;
}

.info-section-id {
    padding: 4px;
    color: #e0dddd;
}

.floor-plan-btn.info-section-btn {
    min-width: 90px;
    padding: 10.5px 9px;
    margin-right: 0;
}

.info-section-row-mapped {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.info-section-row-item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.info-section-row-title {
    font-weight: bold;
}

.info-section-row-mapped input[type="checkbox"] {
    margin: 0px 10px 0px 0px;
}

.info-section-row-mapped .info-section-row-mapped-label {
    padding: 5px 0; 
}

.inventory {
    padding: 5px;
    border-radius: 3px;
    font-size: 1.2vh;
}
.inventory.has-inventory {
    color: #277E20;
    background: #E8FFE6;
    border: 1px solid #277E20;
}

.inventory.no-inventory {
    color: #B78488;
    background: #FDE6E8;
    border: 1px solid #B78488;
}

.info-section-close {
    position: absolute;
    right: 10px;
    top: 17px;
    cursor: pointer;
}