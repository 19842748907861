.floor-empty {
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background: white;
    padding: 12px 10px 7px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.floor-empty-row {
    margin-top: 15px;
}

.floor-empty-title {
    font-weight: bold;
}

.floor-plan-btn.secondary-with-border.floor-empty-action-retry {
    margin-right: 5px;
    padding: 9px 0px;
    min-width: 90px;
}