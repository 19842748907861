/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
	font-family: 'Apercu', 'Apercu Pro', Arial, Helvetica, sans-serif;
	font-size: 16px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.text-very-small {
	font-size: 10px;
}

.text-small {
	font-size: 14px;
}

.text-middle {
	font-size: 16px;
}

.text-big {
	font-size: 20px;
}

.text-end {
	text-align: end;
}

.text-bold {
	font-weight: bold;
}

.text-red {
	color: #e42929;
}

.text-green {
	color: green;
}

.mapping-tool {
	min-width: 950px;
	height: 100vh;
}

.container {
  width: 95%;
  margin: auto;
}

.link {
  color:#6060FF;
  text-decoration: none;
}

.error {
  color: #e42929;
}

.hidden {
    visibility: hidden;
}

.display-none {
	display: none;
}

.text-align-left {
	text-align: left;
}

.floor-plan-line {
	width: 100%;
	margin-top: 0px;
}

.floor-plan-btn {
	color: #FFFFFF;
	background: #3A00FF;
	padding: 15px 10px;
	min-width: 100px;
	border: unset;
    border-radius: 3px;
	cursor: pointer;
}

.floor-plan-btn.disabled {
	color: black;
	background: #E6E6E6;
	cursor: unset;
}

.floor-plan-btn.secondary , .floor-plan-btn.secondary-with-border {
	color: #3A00FF;
	background: #FFFFFF;
  	padding: 12px 0px;
	min-width: 100px;
	border: unset;
  	border-radius: 3px;
	cursor: pointer;
}

.floor-plan-btn.secondary-with-border {
	border: 1px solid #3A00FF;
	border-radius: 3px;
}

.floor-plan-btn.grey {
	color: #3a00ff;
	background: #e0dddd;
}

.floor-plan-btn.secondary.logout {
    padding: 0px;
	width: 100%;
	text-align: end;
}

.username {
	margin-bottom: 5px;
}

.line-through {
	text-decoration-line: line-through;
}
