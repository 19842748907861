.modal-form {
	width: 350px; 
	min-height: 100px; 
	border-radius: 10px;
	border: 1px #faf8f8d8 solid;
	background: #fff;
	position: fixed;
	left: 50%;
	top: 50%;
	margin-top: -150px;
	margin-left: -150px; 
	display: block; 
	z-index: 99; 
	padding: 20px 10px 10px 10px;
}

.modal-form .modal-form-header {
	margin-bottom: 20px;
}

.modal-form .modal-form-body {
	padding: 20px 10px;
}

.modal-form .modal-form-footer {
    display: flex;
    justify-content: center;
}

.modal-form .modal-close {
	width: 14px;
	height: 14px;
	position: absolute;
	top: 7px;
	right: 7px;
	cursor: pointer;
	display: block;

}

.modal-overlay {
	z-index: 98; 
	position: fixed; 
	background-color: #000; 
	opacity: 0.1; 
	width: 100%; 
	height: 100%; 
	top: 0; 
	left: 0; 
	cursor: pointer;
}