
.input {
    position: relative;
    top: 0px;
    left: -46px;
    padding: 13px 10px;
    min-width: 400px;
    border: 1px solid #908989;
    border-radius: 3px;
    outline: 0;
    color: #908989;
}

.input:valid, .valid {
    border-color: #3A00FF;
}

.input-container {
    position: relative;
    min-height: 30px;
    width: 400px;
}

.input-container .input, 
.input-container .result,
.input-container .input-label{
    position: absolute;
    top: 0;
    left: 0;
}

.input-container .input-label {
    top: -8px;
    left: 14px;
    z-index: 2;
    background: white;
    color:#908989;
}

.input-container .input-label.valid {
    color: #3A00FF;
}

.input-container .result {
    width: 400px;
    top: 30px;
    background: white;
    z-index: 2;
}

.result-error {
    margin-top: 20px;
    margin-bottom: 20px;
}

.result {
    margin-top: 10px;
}

.result-title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.result-list-header {
    background: #E6E6E6;
    padding: 12px;
}

.result-list-item {
    padding: 12px;
    border: 1px solid #E6E6E6;
}

.result-list-item {
    border-left-color: #3A00FF;
    border-right-color: #3A00FF;
    cursor: pointer;
}

.result-list-item:hover {
    background: #E6E6E6;
    color: #3A00FF;
}

.result-list-item:last-child {
    border-bottom-color: #3A00FF;
}


