.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	width: 60px;
	height: 60px;
	margin: -76px 0 0 -76px;
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }

  .loader-overlay {
	z-index: 98; 
	position: fixed; 
	background-color: #000; 
	opacity: 0.1; 
	width: 100%; 
	height: 100%; 
	top: 0; 
	left: 0; 
	cursor: pointer;
}