
.search-header {
    margin-top: 40px;
    margin-bottom: 30px;
}

.recent-result {
    margin-top: 30px;
}

.result-list-item-recent {
    padding: 12px;
    border: 1px solid #E6E6E6;
}

.result-list-item-recent {
    color: #3A00FF;
    cursor: pointer;
}

.result-list-item-recent:hover {
    background: #E6E6E6;
}

.result-list-item.search-error {
    color: #969292;
}

.result-list-item.search-error a {
    color: #3A00FF;
    text-decoration: none;
}
