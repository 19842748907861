.container.auth {
    position: fixed;
    top: 30%;
    left: 5%;
}

.auth-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.auth-login-form .auth-login-logo {
    margin-bottom: 30px;
}

.auth-login-form .auth-login-header {
    margin-bottom: 30px;
    font-weight: bold;
}


.auth-login-form .auth-login-btn {
    margin: auto;
}

.auth-login-form .auth-login-row {
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}

.auth-login-form .auth-login-action-row {
    width: 600px;
    display: flex;
    justify-content: space-between;
}

.auth-login-form .auth-login-checkbox {
    margin-right: 10px;
}

.auth-error {
    border: 2px solid red;
    width: 500px;
    padding: 15px 5px;
    text-align: center;
    margin: 20px auto;
}

.auth-error a {
    text-decoration: none;
    color: #3A00FF;
}