.harvest-review-header-top {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.ray-select.ray-select--compact.harvest-review-top-select {
  width: 200px;
  margin-left: 20px;
}

.harvest-review-header-title-created {
  font-family: Apercu;
  font-size: 12px;
  line-height: 150%;
  color: #545e77;
  margin-left: 5px;
  margin-right: 5px;
}

.align-right {
  margin-right: 5px;
  margin-left: auto;
}

.harvest-review-header-title-building {
  font-family: Apercu;
  font-size: 24px;
  line-height: 140%;
  font-weight: bold;
  margin-left: 20px;
}

.harvest-review-diff {
  display: flex;
  margin: 10px auto;
}

.diff-summary {
  width: 60%;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
}
.diff-summary-metrics {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.diff-summary-usf {
  padding-left: 10px;
  width: 15%;
}
.diff-summary-usf-summary {
  z-index: 2;
  background: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 625px;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 5px;
  right: 20px;
  border: 1px solid #eef2f3;
  border-radius: 5px;
  padding: 2px;
}

.diff-summary-usf {
  width: 15%;
}

.diff-summary-metrics {
  width: 85%;
  text-align: center;
}

.diff-summary-metric {
  width: 20%;
  border: 1px solid #e0e4e9;
  padding: 10px 5px 15px 5px;
  background: #f6f7f9;
  cursor: pointer;
}

.diff-summary-metric:hover {
  border: 1px solid blue;
}

.diff-summary-metric.diff-summary-metric-active {
  border: 1px solid blue;
  background: white;
}

.diff-summary-metric-first {
  border-top-left-radius: 7px;
}
.diff-summary-metric-last {
  border-top-right-radius: 7px;
}

.diff-summary-metric-label {
  font-family: Apercu Mono;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
}

.harvest-review-diff-chart-wrapper {
  cursor: pointer;
}

.diff-summary-metric-value-container {
  display: flex;
  text-align: center;
  min-width: 105%;
  min-height: 80%;
  flex-direction: column;
  justify-content: center;
}
.diff-summary-metric-value.more,
.diff-summary-usf-summary-diff.more {
  color: green;
}
.diff-summary-metric-value.less,
.diff-summary-usf-summary-diff.less {
  color: red;
}
.diff-summary-usf-label {
  padding-left: 5px;
}
.diff-summary-usf-label div {
  font-size: 12px;
}
.diff-summary-usf-chart {
  position: relative;
  top: -50px;
  left: 80px;
  height: 0px;
}
.diff-summary-usf-summary-item {
  display: flex;
  min-width: 33%;
  padding: 2px;
}
.diff-summary-usf-summary-item-label,
.diff-summary-usf-summary-item-value {
  font-size: 12px;
}
.diff-summary-usf-summary-item-value {
  padding-left: 5px;
}
.diff-summary-usf-summary-item-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e4e9;
  border-radius: 5px;
  min-width: 75px;
  padding: 1px;
  text-align: center;
}
.diff-summary-usf-summary-item-label.work {
  background: #d2ebf0;
}
.diff-summary-usf-summary-item-label.wash {
  background: #c3c3c3;
}
.diff-summary-usf-summary-item-label.meet {
  background: #b9f0d7;
}
.diff-summary-usf-summary-item-label.circulate {
  background: #fff5e1;
}
.diff-summary-usf-summary-item-label.we {
  background: #fff5cc;
}
.diff-summary-usf-summary-item-label.operate {
  background: #e1e1e1;
}
.diff-summary-usf-summary-item-label.other {
  background: white;
}
.diff-summary-usf-summary-item-value.more {
  color: green;
}
.diff-summary-usf-summary-item-value.less {
  color: red;
}

.harvest-review-main {
  display: flex;
}

.harvest-review--main-info-section {
  overflow: hidden;
  width: 30%;
  padding: 5px;
  background: #e1e4ea;
}

.harvest-review--main-maps-section {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.harvest-review-map {
  position: relative;
  border: 1px solid #a2aabd;
}

.harvest-review-map .floor-plan {
  width: 100%;
  height: 38.4vh;
}

.harvest-review-map-label {
  position: absolute;
  top: 10px;
  left: 10px;
}

.harvest-review-list-item {
  background: white;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px 5px 5px 7%;
  border-radius: 8px;
  cursor: pointer;
}

.harvest-review-list-item.highlighted-room {
  border: 1px solid blue;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
}

.harvest-review-list-item-top {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.harvest-review-list-item-room-number {
  min-width: 85%;
  font-family: Apercu;
  font-size: 16px;
  line-height: 150%;
  font-weight: bolder;
  margin-left: 5px;
  cursor: pointer;
}

.harvest-review-list-item-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
}

.harvest-review-list-item-item-label {
  width: 35%;
  font-family: Apercu Mono;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: #545e77;
}

.harvest-review-list-item-item-previous {
  width: 30%;
  font-family: Apercu;
  font-size: 14px;
  line-height: 150%;
  color: #a2aabd;
}

.harvest-review-list-item-item-previous.previous-the-same {
  color: #e1e4ea;
}

.harvest-review-list-item-item-current {
  width: 35%;
  margin-left: 10%;
}

.harvest-review-list-item-item-current,
.harvest-review-list-item-item-current-val {
  font-family: Apercu;
  font-size: 12px;
  line-height: 150%;
}

.harvest-review-list-item-item-current-diff {
  font-family: Apercu Mono;
  font-size: 10px;
  line-height: 150%;
  color: #545e77;
}

.harvest-review-list-item-item-current-diff.grean {
  color: green;
}

.harvest-review-list-item-item-current-diff.red {
  color: red;
}

.harvest-review-list {
  height: 72vh;
  overflow: scroll;
}

.harvest-review-list-header {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px 5px 5px 7%;
}

.harvest-review-list-header-icon {
  cursor: pointer;
  max-width: 16px;
}

.rotate-90-deg {
  transform: rotate(90deg);
}

.harvest-review-list-item-item-change-icon {
  width: 18px;
  height: 100%;
}

.selected-room {
  filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg)
    brightness(102%) contrast(143%);
}

.hide-furniture-harvest {
  margin-left: 5px;
}

.floor-plan-btn.toggle-left, .floor-plan-btn.secondary.toggle-left {
  border-radius: 3px 0px 0px 3px;
  margin-right: 5px;
}

.header-logo {
  cursor: pointer;
}
